import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, Output, ViewChild, OnDestroy, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { getApplicationConfiguration, getCurrentUser, settings } from '@springtree/eva-sdk-redux';
import stackblitz from '@stackblitz/sdk';
import { cloneDeep, isEqual, kebabCase } from 'lodash-es';
import { Observable, BehaviorSubject, Subject, firstValueFrom, Subscription } from 'rxjs';
import { first, map, mergeMap, tap, filter, take, takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { NgxEditorModel } from '../../components/editor';
import { ClipboardService } from '../../services/clipboard.service';
import { EndPointUrlService } from '../../services/end-point-url.service';
import { EvaTypingsService } from '../../services/eva-typings.service';
import { IListServiceItem } from '../../services/list-services.service';
import { IServiceResponse, ServiceSelectorService } from '../../services/service-selector.service';
import { fadeInOut, listAnimation } from '../../shared/animations';
import { CultureSelectorComponent } from '../culture-selector/culture-selector.component';
import { EditorComponent } from '../editor/editor.component';
import { ITesterState } from '../tester/tester.component';
import { isNil, isEmpty, last } from 'lodash-es';
import { Logger, ILoggable } from 'src/app/decorators/logger';
import { IExtendedGetAvailableServiceDetailsResponseTypeDefinition } from 'src/app/modules/ui-editor/ui-editor/ui-editor.component';
import { getPrimaryKeyPrefix, IndexedDbService, IRequestHistoryEntry, IRequestHistoryEntryCustomer, IRequestHistoryEntryUser } from 'src/app/services/db.service';
import isNotNil from 'src/app/shared/operators/is-not-nil';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

export type TEditorContainerState = Pick<ITesterState, 'editorModel' | 'response' | 'activeTab'>;

export enum ESelectedTabIndex {
  REQUEST = 0,
  RESPONSE = 1,
  REQUEST_HISTORY = 2,
  DEMO = 3,
}

enum SelectedRequestTabIndex {
  EDITOR = 0,
  UserFriendlyEditor = 1
}


/** This component will show the tester for a given service, it can do so with meta data fetched from the /tester/api/services/ end point */
@Logger
@Component({
  selector: 'eva-service-tester',
  templateUrl: './service-tester.component.html',
  styleUrls: ['./service-tester.component.scss'],
  animations: [listAnimation, fadeInOut],
  providers: [CultureSelectorComponent],
  encapsulation: ViewEncapsulation.None,
})
export class ServiceTesterComponent implements ILoggable, AfterViewInit, OnDestroy {
  logger: Partial<Console>;

  /** we will call this in the parent we want to re-fetch the typings after a session expiration */
  @Input() onComponentRefresh$ = new Subject();

  private _testerState: ITesterState;

  public get testerState(): ITesterState {
    return this._testerState;
  }

  @Input()
  public set testerState(testerState: ITesterState) {

    // this.logger.log('testerState called', testerState.listMetaData.Name);

    if (isEqual(testerState, this._testerState)) {
      this.logger.log('testerState isEqual called', testerState.listMetaData.Name);
      return;
    }

    this._testerState = testerState;

    if (testerState) {
      if (testerState.listMetaData) {
        this.editorContainerState = {
          response: this._testerState.response,
          editorModel: this._testerState.editorModel,
          activeTab: this._testerState.activeTab
        };

        this.onServiceChange(testerState);
      }

      if (testerState.detailMetaData) {
        if (!isNil(this.testerStateDetailMetaDataSubscription)) {
          this.testerStateDetailMetaDataSubscription.unsubscribe();
        }
        this.testerStateDetailMetaDataSubscription = testerState.detailMetaData.pipe(takeUntil(this.stop$)).subscribe((detailMetaData) => {
          this.loadRequestHistory(detailMetaData.Request.Type);
          this.detailMetaDataRequest$.next(detailMetaData.Request);
        });
      }
    }

    this.selectedTabIndex = this._testerState.activeTab;

    this.form.get('editor').setValue(testerState.editorModel);
  }


  /**
   * This will be a partial copy of the tester state that will be passed in
   * we will be changing this copy and will notify the parent about the changes
   */
  editorContainerState: TEditorContainerState = {
    editorModel: null,
    response: null,
    activeTab: 1
  };

  @Output() editorContainerStateChange = new EventEmitter<TEditorContainerState>();

  @Output() registerTabGroupAnimationCallback = new EventEmitter();

  public currentService$ = new BehaviorSubject<IListServiceItem>(null);

  public currentServiceResponse$: Observable<IServiceResponse> = this.currentService$.pipe(
    filter(value => !isNil(value)),
    mergeMap(service => this.$serviceSelector.fetch(service.Type))
  );

  public currentTypeSignature$: Observable<string> = this.currentServiceResponse$.pipe(
    map(value => value.Request.Namespace + '.' + value.Request.Type)
  );

  @ViewChild(EditorComponent) monacoEditor: EditorComponent;

  /** This will help us compile different files in the future, when we add tabs support */
  public uniqueURI = `index-${Math.random()}.ts`;

  public monacoModel: NgxEditorModel = {
    language: 'typescript',
    uri: this.uniqueURI,
    value: null
  };

  public monacoOptions = {
    theme: 'vs-dark',
    minimap: {
      enabled: false
    }
  };

  public form = this.formBuilder.group({
    editor: [null],
    userFriendlyEditor: [null]
  });

  /** Whether to expand all the json or not */
  public expandAllJson = null;

  private _selectedTabIndex = ESelectedTabIndex.REQUEST;

  public get selectedTabIndex() {
    return this._selectedTabIndex;
  }

  public set selectedTabIndex(value) {
    this._selectedTabIndex = value;

    this.editorContainerState.activeTab = value;

    this.editorContainerStateChange.emit(this.editorContainerState);
  }

  private _selectedRequestTabIndex = SelectedRequestTabIndex.EDITOR;

  public get selectedRequestTabIndex() {
    const selectedRequestTabIndex = isNil(this._selectedRequestTabIndex)
      ? (+ localStorage.getItem('selectedRequestTabIndex'))
      : this._selectedRequestTabIndex;

    // this.logger.log('returning selectedRequestTabIndex', selectedRequestTabIndex);

    return selectedRequestTabIndex;
  }

  public set selectedRequestTabIndex(newValue) {

    this.logger.log('set selectedRequestTabIndex', newValue);

    this._selectedRequestTabIndex = newValue;

    localStorage.setItem('selectedRequestTabIndex', JSON.stringify(newValue));
  }

  public shouldShowFileAttachment$: Observable<boolean> = this.currentServiceResponse$.pipe(
    map(currentService => (currentService.Request.Fields || []).some(field => field.Type.toLowerCase() === 'byte[]'))
  );

  public isProduction$ = getApplicationConfiguration.getResponse$().pipe(
    map(config => (config?.Configuration?.["EVA:DeploymentStage"] ?? "Unknown") === "Production")
  );

  private monacoLoaded$ = new BehaviorSubject<boolean>(false);

  @ViewChild(EditorComponent) monacoEditorComponent: EditorComponent;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  private stop$ = new Subject<void>();

  private testerStateDetailMetaDataSubscription?: Subscription;
  public detailMetaDataRequest$ = new BehaviorSubject<IExtendedGetAvailableServiceDetailsResponseTypeDefinition | null>(null);
  public requestHistoryEntries = new MatTableDataSource<IRequestHistoryEntry>([]);
  public requestHistoryDisplayedColumns = ['load', 'customer', 'user', 'request', 'timestamp', 'actions'];

  constructor(
    private $evaTypings: EvaTypingsService,
    private $serviceSelector: ServiceSelectorService,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private cultureSelectorComponent: CultureSelectorComponent,
    private $endPointUrlService: EndPointUrlService,
    public $clipboardService: ClipboardService,
    private snackbar: MatSnackBar,
    private db$: IndexedDbService,
  ) {
    this.form.get('editor').valueChanges
      .pipe(
        takeUntil(this.stop$),
        distinctUntilChanged(isEqual),
        tap(() => this.logger.log('editor value change...'))
      )
      .subscribe(async (value) => {
        if (value) {
          this.editorContainerState.editorModel = value;
          this.editorContainerStateChange.emit(this.editorContainerState);

          const compiledEditorInput = await this.compileEditorInput();
          const clonedMetaDataRequest = cloneDeep(this.detailMetaDataRequest$.value);

          for (const field of clonedMetaDataRequest.Fields) {
            if (!isNil(compiledEditorInput[field.Name])) {
              field.value = compiledEditorInput[field.Name];
            } else {
              field.value = undefined;
            }
          }

          this.detailMetaDataRequest$.next(clonedMetaDataRequest);

          const userFriendlyEditorValue = {};

          clonedMetaDataRequest.Fields.map((field) => {
            if (!isNil(field.value)) {
              userFriendlyEditorValue[field.Name] = field.value;
            }
          });

          this.form.get('userFriendlyEditor').setValue(isEmpty(userFriendlyEditorValue) ? null : userFriendlyEditorValue);
        } else {
          // If the value is nil, we will set the initial value of the editor by creating the needed request signature
          //
          this.setEditorTemplate();
        }
      });

    this.form.get('userFriendlyEditor').valueChanges.pipe(
      takeUntil(this.stop$),
      isNotNil(),
      distinctUntilChanged(isEqual)
    ).subscribe((userFriendlyEditorValue) => {
      Object.keys(userFriendlyEditorValue).forEach((key) => {
        const value = userFriendlyEditorValue[key];
        if (isNil(value)) {
          delete userFriendlyEditorValue[key];
        }
      });
      this.setEditorTemplate(userFriendlyEditorValue);
    });

    this.onComponentRefresh$.pipe(
      takeUntil(this.stop$)
    ).subscribe(() => {
      this.setTypingsForService(this.testerState.listMetaData.Name);
    });
  }

  /** Whenever a service is selected, we will fetch it and create a code template */
  onServiceChange(testerState: ITesterState) {
    this.currentService$.next(testerState.listMetaData);

    this.selectedTabIndex = testerState.activeTab;

    this.setTypingsForService(this.testerState.listMetaData.Name);

    this.form.get('userFriendlyEditor').reset();
  }

  async setEditorTemplate(requestPayload?: any) {
    const currentTypeSignature = await firstValueFrom(this.currentTypeSignature$.pipe(isNotNil()));

    const newEditorValue = this.createCodeTemplate(currentTypeSignature, requestPayload);

    if (!isNil(requestPayload)) {
      const currentEditorValue = await this.compileEditorInput();

      if (!isEqual(currentEditorValue, requestPayload)) {
        this.form.get('editor').setValue(newEditorValue, { emitEvent: false });
      }
    } else {
      this.form.get('editor').setValue(newEditorValue, { emitEvent: false });
    }
  }

  /** Whenever a file is uploaded, we will try and find a field that accepts a byte[] */
  async onFileUpload(base64: string) {
    const matchingField = await firstValueFrom(this.currentServiceResponse$.pipe(map(currentService => currentService.Request.Fields.find(field => field.Type.toLowerCase() === 'byte[]'))));

    const currentTypeSignature = await firstValueFrom(this.currentTypeSignature$.pipe(isNotNil()));


    let newRequestPayload = {};

    const unpaddedBase64 = base64.substring(base64.indexOf(',') + 1);

    try {
      const json = await this.compileEditorInput();

      newRequestPayload = {
        ...json,
        [matchingField.Name]: unpaddedBase64,
        MimeType: 'base64'
      };

    } catch (error) {
      // Means we failed to compile, so we will just override the editor
      //

      newRequestPayload = {
        [matchingField.Name]: base64
      };
    }

    const newEditorValue = this.createCodeTemplate(currentTypeSignature, newRequestPayload);

    this.form.get('editor').setValue(newEditorValue);
  }


  monacoLoad() {
    this.monacoLoaded$.next(true);
  }

  monacoPaste(event: ClipboardEvent) {

    event.stopPropagation();
    event.preventDefault();

    // Get pasted data via clipboard API
    const clipboardData = event.clipboardData;

    const pastedData = clipboardData.getData('Text');

    this.logger.log('pastedData', pastedData);

    try {
      const parsedJson = JSON.parse(pastedData);

      this.logger.log('parsedJson', parsedJson);

      // We only want to set valid objects.
      //
      if (typeof parsedJson === 'object') {
        this.setEditorTemplate(parsedJson);
      }

    } catch (error) {
      this.logger.error('the user pasted invalid JSON in the editor', error);

      this.snackbar.open('If you are trying to paste JSON, make sure you use a valid JSON structure.', null, { duration: 3000 });
    }
  }

  async setTypingsForService(serviceName: string) {

    await firstValueFrom(this.monacoLoaded$.pipe(first(Boolean)));

    const extraLibName = `eva-${kebabCase(serviceName)}.d.ts`;

    this.logger.log(`setTypingsForService adding '${extraLibName}' as lib.`);

    // see https://microsoft.github.io/monaco-editor/api/interfaces/monaco.languages.typescript.languageservicedefaults.html#getextralibs
    //
    const extraLibs: monaco.languages.typescript.IExtraLibs = monaco.languages.typescript.typescriptDefaults.getExtraLibs();

    if (extraLibName in extraLibs) {
      this.logger.log(`setTypingsForService '${extraLibName}' was already added as lib, returning early.`);
      return;
    }

    try {
      const typings = await firstValueFrom(this.$evaTypings.getTypingsForService(serviceName).pipe(isNotNil()));

      monaco.languages.typescript.typescriptDefaults.addExtraLib(typings, extraLibName);
    } catch (error) {
      this.snackbar.open('Error loading typings', null, { duration: 3000, verticalPosition: 'bottom', horizontalPosition: 'center' });

      this.logger.error('Error loading typings', error);
    }

  }

  async performRequest(event?: KeyboardEvent) {

    if (await firstValueFrom(this.isProduction$) === true) {
      return;
    }

    if (event) {
      event.preventDefault();
    }

    let request = null;

    if (this.selectedRequestTabIndex === SelectedRequestTabIndex.UserFriendlyEditor) {
      request = this.form.get('userFriendlyEditor').value;
    } else {
      request = await this.compileEditorInput();
    }

    const culture = this.cultureSelectorComponent.getCultureKey();

    const httpOptions = { headers: {} };

    if (culture) {
      httpOptions.headers = new HttpHeaders({
        'Accept-Language': culture
      });
    }

    const currentService = await firstValueFrom(this.currentServiceResponse$);

    const currentOrganization = await firstValueFrom(getCurrentUser.getResponse$().pipe(
      isNotNil(),
      map(response => {
        return {
          id: response.User.CurrentOrganizationID,
          name: response.User.CurrentOrganizationName,
        } as IRequestHistoryEntryCustomer;
      }),
    ));

    const currentUser = await firstValueFrom(getCurrentUser.getResponse$().pipe(
      isNotNil(),
      map(response => {
        return {
          id: response.User.ID,
          name: response.User.FullName,
        } as IRequestHistoryEntryUser;
      }),
    ));

    const serviceRoute = currentService.Routes[0] ? currentService.Routes[0].Path : `/message/${currentService.Request.Type}`;

    // To:do take Accept-Language into account, when the culture selector is built
    //
    try {
      const response = await firstValueFrom(this.http.post<any>(`${this.$endPointUrlService.endPointUrl}${serviceRoute}`, {
        ...request,
      }, httpOptions));

      this.editorContainerState.response = response;
      const lastHistoryEntry = this.requestHistoryEntries.data[0];

      if (!isEqual(lastHistoryEntry?.request, request)) {
        const prefix = await getPrimaryKeyPrefix();
        this.db$.addEntity('requestHistory', {
          id: currentService.Request.Type,
          prefix,
          timestamp: (new Date()).getTime(),
          request,
          customer: currentOrganization,
          user: currentUser,
        } as IRequestHistoryEntry);
        this.loadRequestHistory(currentService.Request.Type);
      }
    } catch (exception) {
      this.editorContainerState.response = exception.error;
    }

    this.selectedTabIndex = ESelectedTabIndex.RESPONSE;
    this.editorContainerStateChange.emit(this.editorContainerState);
  }

  async compileEditorInput() {

    const model = monaco.editor.getModels()
      .find(potentialMatchingModel => potentialMatchingModel.uri.path.includes(this.uniqueURI));
    /** @see https://github.com/Microsoft/monaco-typescript/pull/8 */
    const worker = await monaco.languages.typescript.getTypeScriptWorker();

    const client = await worker(model.uri);

    const output = await client.getEmitOutput(model.uri.toString());

    const matchingOutput: { name: string, text: string } = output.outputFiles.find(potentialMatchingOutput => {
      /** transforming ts uri to js because output files will be javascript files */
      const jsURI = this.uniqueURI.replace('ts', 'js');
      const match = potentialMatchingOutput.name.includes(jsURI);

      return match;
    });

    this.logger.log(matchingOutput);

    // Joining the js object array and removing the semicolon so its valid json
    //
    let jsObject: string = matchingOutput.text
      .replace('const request =', '') // getting rid of the assignemnt
      .replace(/\r?\n|\r/g, ''); // getting rid of new spaces

    // If there is a semicolon as last character, remove it
    //
    if (last(jsObject) === ';') {
      jsObject = jsObject.slice(0, jsObject.length - 1);
    }

    try {
      // tslint:disable-next-line:no-eval
      const jsonObject: Object = eval(`(${jsObject})`);

      return jsonObject;
    } catch (e) {
      // catch the error as it isn't valid js/ts
      //
    }
    return {};
  }

  /** Returns the template that casts an empty object to a given eva type */
  createCodeTemplate(requestType: string, requestPayload = {}): string {

    let codeTemplate: string;

    if (isEmpty(requestPayload)) {
      codeTemplate = [
        `const request: Partial<${requestType}> = {`,
        '   ',
        `}`
      ].join('\n');
    } else {
      const jsonValue = JSON.stringify(requestPayload, null, 4);
      codeTemplate = [
        `const request: Partial<${requestType}> = `,
        jsonValue
      ].join('');
    }

    return codeTemplate;
  }

  copyResponse(response: any) {
    this.$clipboardService.copyToClipboard(JSON.stringify(response, null, 2));

    this.snackbar.open('Response copied to clipboard', null, { duration: 3000 });
  }

  resizeMonacoEditor() {
    if (this.monacoEditor && this.monacoEditor._editor) {
      this.monacoEditor._editor.layout();

      this.logger.log('layout method called on monaco editor instance');
    }
  }

  async openCodeSample() {

    const serviceName = this.testerState.listMetaData.Name;

    const reducerName = serviceName.charAt(0).toLowerCase() + serviceName.slice(1);

    const typings = await firstValueFrom(this.$evaTypings.getTypingsForService(this.testerState.listMetaData.Name).pipe(isNotNil()));

    const currentTypeSignature = await firstValueFrom(this.currentTypeSignature$);

    stackblitz.openProject({
      template: 'typescript',
      files: {
        'index.ts':
          `import { core } from '@springtree/eva-sdk-redux';
           import ${reducerName}Fn from  './${reducerName}'
           import JSONFormatter from 'json-formatter-js';
            core.bootstrap({
            defaultToken: '${settings.userToken}',
            endPointUrl: '${settings.endPointURL}',
            appName: 'tester-demo',
            appVersion: '1.0.0',
            disableCartBootstrap: true,
            disableDataBootstrap: true,
          }).then(() => {
            return ${reducerName}Fn();
          }).then( response => {
            const formatter = new JSONFormatter(response, 2, {
                theme: 'dark',
                hoverPreviewEnabled: true,
            });

            const el = formatter.render()

            document.querySelector('#app').innerHTML = null;

            document.querySelector('#app').appendChild(el);
          }).catch(()=>{});
        `
        ,
        [`${reducerName}.ts`]:
          [
            `/** ⚠️  The store might not contain this reducer yet */`,
            `import { store, ${reducerName} } from '@springtree/eva-sdk-redux';`,
            `export default () => {`,
            `  const [action, fetchPromise] = ${reducerName}.createFetchAction({`,
            `  `,
            `  } as Partial<${currentTypeSignature}>);`,
            `  `,
            `  store.dispatch(action)`,
            `  `,
            `  // Promise usage`,
            `  fetchPromise.then( ${reducerName}Response => {`,
            `    console.log(${reducerName}Response)`,
            `  }).catch( error => {`,
            `    console.error(error)`,
            `  });`,
            `  return fetchPromise`,
            `}`
          ].join('\n')
        ,
        'index.html': `
        <div id="app"></div>
        <style>
          body {
            background: black;
          }
        </style>
        `,
        'eva.d.ts': typings
      },
      dependencies: {
        '@springtree/eva-sdk-redux': '*',
        'lodash-es': '*',
        'rxjs': '5.5.12',
        'json-formatter-js': '*'
      },
      title: serviceName,
      description: `Auto created from ${window.location.origin}/service/${serviceName}`
    }, {
      devToolsHeight: 500,
      openFile: `${reducerName}.ts`
    });
  }

  async loadRequestHistory(requestName: string) {
    const prefix = await getPrimaryKeyPrefix();
    const entries = await this.db$.getAllEntities<IRequestHistoryEntry>('requestHistory', `${prefix}${requestName}`);
    this.requestHistoryEntries = new MatTableDataSource<IRequestHistoryEntry>(entries);

    if (this.paginator) {
      this.requestHistoryEntries.paginator = this.paginator;
    }

    if (entries.length === 0) {
      this.selectedTabIndex = ESelectedTabIndex.REQUEST;
    }
  }

  async loadHistoryRequest(historyEntry: IRequestHistoryEntry) {
    await this.setEditorTemplate(historyEntry.request);
    this.selectedTabIndex = ESelectedTabIndex.REQUEST;
  }

  async removeHistoryRequest(historyEntry: IRequestHistoryEntry) {
    await this.db$.removeEntity('requestHistory', historyEntry.id);
    const currentService = await firstValueFrom(this.currentServiceResponse$);
    this.loadRequestHistory(currentService.Request.Type);
  }

  getStringifiedRequest(request: any) {
    return JSON.stringify(request).replace('\\"', '"');
  }

  ngAfterViewInit() {
    if (isNil(this.requestHistoryEntries.paginator)) {
      this.requestHistoryEntries.paginator = this.paginator;
    }
  }

  ngOnDestroy(): void {
    this.stop$.next();
  }
}

<eva-file-picker (base64Upload)="onFileUpload($event)" *ngIf="shouldShowFileAttachment$ | async"></eva-file-picker>

<mat-tab-group [(selectedIndex)]="selectedTabIndex" (animationDone)="resizeMonacoEditor()">
  <mat-tab label="Request">
    <ng-template matTabContent>
      <form [formGroup]="form">
        <mat-tab-group [(selectedIndex)]="selectedRequestTabIndex" (animationDone)="resizeMonacoEditor()">
          <mat-tab label="Editor">
            <ng-template matTabContent>
              <eva-monaco-editor (keydown.control.Enter)="performRequest($event)"
                (keydown.meta.Enter)="performRequest($event)" [options]="monacoOptions" [model]="monacoModel"
                formControlName="editor" (monacoLoad)="monacoLoad()" (paste)="monacoPaste($event)"></eva-monaco-editor>
            </ng-template>
          </mat-tab>
          <mat-tab label="Form"  *ngIf="(detailMetaDataRequest$ | async) as detailMetaData">
            <ng-template matTabContent>
              <eva-ui-editor *ngIf="form.get('userFriendlyEditor')" [field]="detailMetaData"
                formControlName="userFriendlyEditor"></eva-ui-editor>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </form>

      <div *ngIf="!(isProduction$ | async)" class="no-gutters row justify-content-end">
        <button mat-button (click)="performRequest($event)">Submit</button>
      </div>
    </ng-template>
  </mat-tab>

  <mat-tab label="Response" [disabled]="!editorContainerState.response">
    <ng-template matTabContent>
      <div class="response-buttons-top pt-1">
        <button mat-raised-button (click)="expandAllJson = !expandAllJson" class="mr-1">Toggle</button>
        <button mat-raised-button (click)="copyResponse(editorContainerState.response)" class="mr-1">Copy</button>
      </div>
      <eva-json-formatter [expandAll]="expandAllJson" [json]="editorContainerState.response"></eva-json-formatter>
    </ng-template>
  </mat-tab>

  <mat-tab label="Request history" [disabled]="requestHistoryEntries.data.length === 0">
    <ng-template matTabContent>
      <table mat-table [dataSource]="requestHistoryEntries" class="request-history-table">
        <ng-container matColumnDef="load">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <button mat-button (click)="loadHistoryRequest(row)" class="mr-1"><mat-icon>refresh</mat-icon></button>
          </td>
        </ng-container>
        <ng-container matColumnDef="customer">
          <th mat-header-cell *matHeaderCellDef>Customer</th>
          <td mat-cell *matCellDef="let row">{{row?.customer?.name}}</td>
        </ng-container>
        <ng-container matColumnDef="user">
          <th mat-header-cell *matHeaderCellDef>User</th>
          <td mat-cell *matCellDef="let row">{{row.user?.name}}</td>
        </ng-container>
        <ng-container matColumnDef="request">
          <th mat-header-cell *matHeaderCellDef>Request</th>
          <td mat-cell *matCellDef="let row">{{getStringifiedRequest(row.request)}}</td>
        </ng-container>
        <ng-container matColumnDef="timestamp">
          <th mat-header-cell *matHeaderCellDef>Last modified</th>
          <td mat-cell *matCellDef="let row">{{ row?.timestamp | localizedDate: 'mediumDateTime' }}</td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row" class="action-cell">
            <button mat-button (click)="removeHistoryRequest(row)" class="mr-1 only-on-hover"><mat-icon>close</mat-icon></button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="requestHistoryDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: requestHistoryDisplayedColumns;"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[10, 20, 30, 40, 50]" showFirstLastButtons aria-label="Select page of history requests elements"></mat-paginator>
    </ng-template>
  </mat-tab>

  <!-- <mat-tab label="Live demo">
    <p class="py-3">This will allow you to edit the request and see changes in real time, it will also teach you how to use the <a href="https://github.com/springtreesolutions/eva-sdk-redux">eva-sdk-redux</a> in your applications</p>

    <button mat-button (click)="openCodeSample()">
      Open demo
      <mat-icon>open_in_new</mat-icon>
    </button>
  </mat-tab> -->
</mat-tab-group>
